<template>
  <validation-observer ref="simpleRules">
    <b-form class="form_custom">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-button
                variant="danger"
                type="submit"
                class="float-right btn-sm"
                @click.prevent="validationForm"
              >
                {{ this.$route.params.id ? 'Cập nhật' :$t('save_info') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('name_permistion')"
                  rules="required"
                >
                  <label
                    for="name"
                    :class="errors.length > 0 ? 'text-danger': null"
                  >{{ $t('name_permistion') }} <span class="text-danger">(*)</span></label>
                  <b-form-input
                    v-model="frm.name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('placehoder_per')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-radio-group
                v-model="frm.status"
                :options="options"
                class="demo-inline-spacing custom_radio_checker mb-2"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </b-col>
          </b-row>
          <b-card-code title="">
            <table class="permission_list table table-borderless">

              <tbody>
                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall" />
                    Dashboards
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="dashboard_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                </tr>
                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.user"
                        class="custom-control-danger"
                        @change="val => toggle('user', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('account_management') }}
                  </th>
                </tr>

                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="user_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="user_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="user_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="user_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.bankQuestions"
                        class="custom-control-danger"
                        @change="val => toggle('bankQuestions', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_bank_questions') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="bankQuestions_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="bankQuestions_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="bankQuestions_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="bankQuestions_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.capacity"
                        class="custom-control-danger"
                        @change="val => toggle('capacity', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('capacity_manager') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="capacity_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="capacity_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="capacity_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="capacity_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.course"
                        class="custom-control-danger"
                        @change="val => toggle('course', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_course') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="course_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="course_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="course_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="course_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.exercise"
                        class="custom-control-danger"
                        @change="val => toggle('exercise', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_exercise') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="exercise_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="exercise_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="exercise_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="exercise_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.website"
                        class="custom-control-danger"
                        @change="val => toggle('website', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    Quản lý website
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="website_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="website_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="website_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="website_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.general"
                        class="custom-control-danger"
                        @change="val => toggle('general', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_general') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="general_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="general_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="general_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="general_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.permission"
                        class="custom-control-danger"
                        @change="val => toggle('permission', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_permission') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="permission_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="permission_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="permission_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="permission_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

                <tr class="theadHead">
                  <th
                    colspan="4"
                  >
                    <div class="thead_checkall">
                      <b-form-checkbox
                        v-model="cbx.admin"
                        class="custom-control-danger"
                        @change="val => toggle('admin', val)"
                      >{{ $t('choose_all') }}</b-form-checkbox>
                    </div>
                    {{ $t('manager_account_admin') }}
                  </th>
                </tr>
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="admin_create"
                      class="custom-control-danger"
                    >
                      {{ $t('add') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="admin_view"
                      class="custom-control-danger"
                    >
                      {{ $t('view') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="admin_edit"
                      class="custom-control-danger"
                    >
                      {{ $t('edit') }}
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="frm.roles"
                      value="admin_delete"
                      class="custom-control-danger"
                    >
                      {{ $t('delete') }}
                    </b-form-checkbox>
                  </td>
                </tr>

              </tbody>
            </table>
          </b-card-code>
        </b-col>

      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormRadioGroup, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import _ from 'lodash'

export default {
  components: {
    BRow, BCol, BCardCode, BFormGroup, BFormInput, ValidationProvider, BButton, ValidationObserver, BForm, BFormRadioGroup, BFormCheckbox, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      cbx: {
        dashboard: 0,
        user: 0,
        course: 0,
        exercise: 0,
        input: 0,
        website: 0,
        general: 0,
        permission: 0,
        admin: 0,
        bankQuestions: 0,
        capacity: 0,
      },
      frm: {
        id: this.$route.params.id,
        status: 1,
        name: '',
        roles: [],
      },
      roleGroup: {
        dashboard: [
          'dashboard_view',
        ],
        user: [
          'user_create',
          'user_view',
          'user_edit',
          'user_delete',
        ],
        course: [
          'course_create',
          'course_view',
          'course_edit',
          'course_delete',
        ],
        exercise: [
          'exercise_create',
          'exercise_view',
          'exercise_edit',
          'exercise_delete',
        ],
        input: [
          'input_create',
          'input_view',
          'input_edit',
          'input_delete',
        ],
        website: [
          'website_create',
          'website_view',
          'website_edit',
          'website_delete',
        ],
        general: [
          'general_create',
          'general_view',
          'general_edit',
          'general_delete',
        ],
        permission: [
          'permission_create',
          'permission_view',
          'permission_edit',
          'permission_delete',
        ],
        admin: [
          'admin_create',
          'admin_view',
          'admin_edit',
          'admin_delete',
        ],
        bankQuestions: [
          'bankQuestions_create',
          'bankQuestions_view',
          'bankQuestions_edit',
          'bankQuestions_delete',
        ],
        capacity: [
          'capacity_create',
          'capacity_view',
          'capacity_edit',
          'capacity_delete',
        ],
      },
      options: [
        { text: this.$t('active'), value: 1, disabled: false },
        { text: this.$t('no_active'), value: 0, disabled: false },
      ],
      required,
    }
  },
  watch: {
    'frm.roles': {
      handler(value) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const role in this.roleGroup) {
          this.cbx[role] = this._.difference(this.roleGroup[role], value).length === 0
        }
      },
    },
  },
  created() {
    if (this.frm.id) this.loadItem()
  },
  methods: {
    loadItem() {
      this.isLoading = true
      this.$http.get(`/roles/${this.frm.id}`)
        .then(res => {
          const { data } = res
          this.frm.id = data.id
          this.frm.name = data.name
          this.frm.status = data.status
          this.frm.roles = data.permissions
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    toggle(role, value) {
      if (value) {
        this.frm.roles.push(...this.roleGroup[role])
      } else {
        this.frm.roles = this.frm.roles.filter(v => {
          const index = this.roleGroup[role].indexOf(v)
          return index === -1
        })
      }

      _.uniqBy(this.frm.roles, e => e)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const method = this.frm.id ? 'put' : 'post'
          const url = this.frm.id ? `/roles/${this.frm.id}` : '/roles'
          this.$http({
            method,
            url,
            data: this.frm,
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.$router.push({ name: 'roles-list' })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(e => {
              this.$bvToast.toast('Cập nhật thất bại', {
                title: 'Thông báo',
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style>
table.permission_list th, table.permission_list td {
  text-align: center;
}

table.permission_list tr:nth-child(odd) {
  background-color: #f8f8f8
}
.custom-control-label{
  float:  left !important;
}
</style>
